import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authAction from "../../redux/auth/actions";
import { logigloUrl } from "../../containers/Config/index";
import axios from "axios";
import logo from "../../assests/logo.png";
import { FaWhatsappSquare } from "react-icons/fa";
import Chatbot from "../chatBot";
import "./index.css";



const Navbar = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [dropdownMenu, setdropdownMenu] = useState("");
  const [linkedInSignupStatus, setLinkedInSignupStatus] = useState(null);
  const [scrollingText, setScrollingText] = useState("");
  const containerRef = useRef(null);
  const [scrollingStatus, setScrollingStatus] = useState(null);
  const [scrollingMovement, setScrollingMovement] = useState(null);
  const [scrollinPosition, setScrollinPosition] = useState("Right");

  const location = useLocation();

  const phoneNumber = "+919246295035";
  const message = "Hello";

  // Conditionally check if the current path is /Education or /Events
  const showBackButton =
    location.pathname === "/Education" ||
    location.pathname === "/Events" ||
    location.pathname === "/Careers" ||
    location.pathname === "/Blog";

  const scrollbarTheme = useSelector(
    (state) => state.ThemeSwitcher.scrollbarTheme.backgroundColor
  );

  const scrollTestColor = useSelector((state) => state);

  let scrollingBackgroundClr;

  const scrollTheme = localStorage.getItem("themeData");

  if (scrollTheme) {
    const parsedScroll = JSON.parse(scrollTheme);

    const scrollingBackgroundClr = parsedScroll.scrollbarTheme.backgroundColor;

    // console.log(scrollingBackgroundClr, "themetheme");
  }

  // console.log(scrollingText, "text")

  useEffect(() => {
    const fetchFeatureStatus = async (feature) => {
      try {
        const response = await axios.get(`${logigloUrl}/getFeatureStatus`, {
          params: { feature },
        });
        if (feature === "linkedIn_signup") {
          setLinkedInSignupStatus(response.data.status === 1);
        } else if (feature === "navbar_scrolling") {
          setScrollingStatus(response.data.status === 1);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchFeatureStatus("linkedIn_signup");
    fetchFeatureStatus("navbar_scrolling");
  }, []);

  // Scrol Movement Status
  useEffect(() => {
    const fetchScrolStatus = async (feature) => {
      try {
        const response = await axios.get(
          `${logigloUrl}/getScrolMovementStatus`
        );

        const scrollMovement = response.data[0].scroll_movement;
        const scrollPosition = response.data[0].scroll_position;

        // console.log(scrollMovement, "mnmnmn");
        // console.log(scrollPosition, "mnmnmn");

        setScrollingMovement(scrollMovement === 1);

        setScrollinPosition(scrollPosition);
      } catch (error) {
        console.log(error);
      }
    };

    fetchScrolStatus();
  }, []);

  // Navbar Scrolling Text
  useEffect(() => {
    axios
      .get(`${logigloUrl}/getScrollData`)
      .then((res) => {
        // console.log(res.data.scrollingText)
        setScrollingText(res.data.scrollingText.text);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${logigloUrl}/menuItems`)
      .then((response) => {
        setMenuItems(response.data);
        // console.log(response.data, "menu");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${logigloUrl}/dropdownMenu`)
      .then((response) => {
        setdropdownMenu(response.data);

        // console.log(response.data, "menu");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const token = useSelector((state) => state.Auth.idToken);
  const accountId = useSelector((state) => state.Auth.accountId);

  // console.log(accountId, "ac_test", accountId === undefined);

  let accountType = null;

  // Check if token exists in localStorage
  const storedToken = localStorage.getItem("token");
  if (storedToken) {
    const parsedToken = JSON.parse(storedToken);
    // Check if accountType exists in parsedToken
    if (parsedToken && parsedToken.accountType) {
      accountType = parsedToken.accountType;
    }
  }

  // Function to handle navigating back to the dashboard
  const handleBackToDashboard = () => {
    // console.log(token, "tokentokentoken");

    if (token) {
      history.push("/forumDashboard");
    } else {
      history.push("/signin");
    }
  };

  // Now use accountType in your navbar code as needed

  const history = useHistory();
  const { logout } = authAction;

  const dispatch = useDispatch();

  const logouts = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("themeData");
    history.replace("/");
    logout();
  };

  const restartAnimation = () => {
    containerRef.current.style.animation = "none"; // Pause the animation
    setTimeout(() => {
      containerRef.current.style.animation = "scroll 20s linear infinite"; // Restart the animation after 1 second
    }, 1000); // 1 second delay
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-white fixed-top "
      style={{
        borderBottom: "0.3px solid gray",
      }}
    >
      <div className="container-fluid navbar-inner-fluid-container">
        <Link className="navbar-brand" to="/">
          <img
            // className="brand-logo"
            className="navbar-brand"
            style={{ width: "100px", height: "50px" }}
            src={logo}
            alt="Logiglo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse" // Note the change here
          aria-expanded="false"
          aria-label="Toggle navigation"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div style={{ width: "100%" }}>
          <div
            className="collapse navbar-collapse main-nav menu-items-container"
            style={{ backgroundColor: "transparet" }}
            id="navbarNav"
          >
            <div
              style={{ color: "black", overflow: "hidden" }}
              className="scroll-div"
            >
              {scrollingStatus && (
                <>
                  {scrollingStatus && !scrollingMovement ? (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        marginBottom: "5px",
                        textAlign: scrollinPosition.toLowerCase(),
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          backgroundColor: "#FFDA7F",
                          padding: "3px",
                          borderRadius: "4px",
                          fontSize: "14px",

                          // textAlign: scrollinPosition.toLowerCase(),
                        }}
                      >
                        {scrollingText}
                      </span>
                    </div>
                  ) : (
                    <div
                      ref={containerRef}
                      style={{
                        whiteSpace: "nowrap",
                        animation: "scroll 20s linear infinite",
                        marginBottom: "5px",
                      }}
                      onAnimationIteration={restartAnimation}
                    >
                      <span
                        style={{
                          color: "black",
                          backgroundColor: "#FFDA7F",
                          padding: "3px",
                          borderRadius: "4px",
                          fontSize: "14px",
                        }}
                      >
                        {scrollingText}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="nav-itemss">
              <ul
                className="navbar-nav ml-auto inner-nav-items-container"
                style={{ fontSize: "14px" }}
              >
                {Array.isArray(menuItems) &&
                  menuItems
                    .filter((item) => item.menu_items !== "Company")
                    .map(
                      (item) =>
                        item.display === 1 &&
                        (item.menu_items !== "Dashboard" || token) && (
                          <li className="nav-item" key={item.id}>
                            <Link
                              className="nav-link"
                              to={
                                accountType === "admin"
                                  ? item.menu_items === "Forum"
                                    ? token
                                      ? `/forumDashboard/admin`
                                      : "/signin?from=forum"
                                    : item.menu_items === "Careers"
                                    ? "/Careers/admin"
                                    : item.navigation_link
                                  : item.menu_items === "Forum"
                                  ? token
                                    ? `/forumDashboard`
                                    : `/forum`
                                  : item.navigation_link
                              }
                            >
                              {item.menu_items}
                            </Link>
                          </li>
                        )
                    )}

                {menuItems
                  .filter(
                    (eachItem) =>
                      eachItem.menu_items === "Company" &&
                      eachItem.display === 1
                  )
                  .map((eachItem) => (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Company
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                        style={{ fontSize: "14px" }}
                      >
                        {Array.isArray(dropdownMenu) &&
                          dropdownMenu.map(
                            (item) =>
                              item.display === 1 && (
                                <Link
                                  className="dropdown-item"
                                  to={item.option_navigation_link}
                                  key={item.id} // Assuming each item has a unique id
                                >
                                  {item.company_dropdown}
                                </Link>
                              )
                          )}
                      </div>
                    </li>
                  ))}

                <></>
              </ul>

             
                   
                 

              <ul className="navbar-nav ms-auto" style={{ fontSize: "10px" }}>


              
              <Chatbot/>

                {/* Whats App   */}
                {/* <a
                  href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                    message
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    position: "fixed",
                    bottom: "120px",
                    right: "20px",
                    zIndex: 9999,
                    backgroundColor: "#25D366", // WhatsApp green
                    borderRadius: "50%",
                    padding: "14px",
                  }}
                >
                  <FaWhatsappSquare size={35} color="white" />
                </a> */}

                

                {/* Render Back to Dashboard button only for /Education or /Events routes */}
                {showBackButton && token && (
                  <button
                    className="back-to-dashboard-btn"
                    onClick={handleBackToDashboard}
                  >
                    Back to Dashboard
                  </button>
                )}

                {token ? (
                  <button onClick={logouts} className="logout-Btn-styles">
                    Log out
                  </button>
                ) : (
                  <li className="nav-item">
                    <Link to="/signin?from=">
                      <button className="login-Btn-styles">Log in</button>
                    </Link>
                  </li>
                )}

                {!token && (
                  <li className="nav-item">
                    {linkedInSignupStatus ? (
                      <Link to={`/linkedInSignup`}>
                        <button className="signup-Btn-styles">Sign up</button>
                      </Link>
                    ) : (
                      <Link to={`/signup`}>
                        <button className="signup-Btn-styles">Sign up</button>
                      </Link>
                    )}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
