import { SET_PENDING_REPLY_COUNT } from "./replyActions";

const initialState = {
  pendingReplyCount: 0,
};


const savedState = JSON.parse(localStorage.getItem("pendingReplyCount")) || initialState;

const replyReducer = (state = savedState, action) => {
    switch (action.type) {
      case SET_PENDING_REPLY_COUNT:
        const newState = { ...state, pendingReplyCount: action.payload };
        localStorage.setItem("pendingReplyCount", JSON.stringify(newState));
        return newState;
      default:
        return state;
    }
  };

export default replyReducer;
