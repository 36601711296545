import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { RiMore2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { logigloUrl } from "../Config";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import { toast ,ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState("Posts");
  const [userData, setUserData] = useState();
  const [events, setEvents] = useState([]);   
  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
  const [selectedEvent, setSelectedEvent] = useState(null); 
    const [title, setTitle] = useState("");
    const [organizer, setOrganizer] = useState("");
    const [location, setLocation] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);
    const[countryCode,setCountryCode]=useState('')
const [currentEvent, setCurrentEvent] = useState(null);  
const [updatedEvent, setUpdatedEvent] = useState({});  
  const { userId } = useParams();
  const account = localStorage.getItem("token");
  const parsedAccount = JSON.parse(account);
  const accountId = parsedAccount.accountId;

  const idToFetch = userId || accountId;
  

  // Switch tab logic
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleFlagModal = () => {
    setIsFlagModalOpen(!isFlagModalOpen);
  };

  // Fetch user data (posts, comments, etc.)
  const fetchUserData = async () => {
    try {
      const data = await fetch(`${logigloUrl}/userAccountData/${idToFetch}`);
      const totalData = await data.json();
      setUserData(totalData);
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch user events
  const fetchUserEvents = async () => {
    try {
      const data = await fetch(`${logigloUrl}/fetchEvents?accountId=${idToFetch}`);
      const eventData = await data.json();
      // console.log("Event data after fetch",eventData);
      
      // Filter events based on accountId
      const userEvents = eventData.data.filter(event => event.account_id === parseFloat(idToFetch));

      // console.log(userEvents, "userEvents");
      
      setEvents(userEvents);   
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchUserEvents();   
  }, [idToFetch]);   
  const formatDate = (dateString) => {
    const zonedTime = utcToZonedTime(dateString, "Asia/Kolkata"); // Convert to IST time zone
    return format(zonedTime, "MMMM d, yyyy hh:mm a 'IST'"); // Format the date
  };

  const createMarkup = (html) => {
    return { __html: html };
  };
  const toggleMenu = (eventId) => {
   setActiveEvent(activeEvent === eventId ? null : eventId);  
 };

 const handleDeleteClick = (eventId) => {
   setEventToDelete(eventId);
   setIsDeleteModalOpen(true);
 };

 

 const deleteEvent = async () => {
   try {
     const response = await fetch(`${logigloUrl}/deleteEvent/${eventToDelete}`, {
       method: "DELETE",
     });

     if (response.ok) {
       // Remove the deleted event from the state
       setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventToDelete));
       setIsDeleteModalOpen(false);
       setEventToDelete(null);
        
     } else {
       console.error("Failed to delete event.");
     }
   } catch (error) {
     console.error("Error deleting event:", error);
   }
   setActiveEvent(null);
 };

 // Open Edit Modal with Pre-filled Data
const handleEdit = (event) => {
   setCurrentEvent(event);
   setUpdatedEvent({ ...event });  
   setIsEditModalOpen(true);
 };
 
 // Handle Input Changes in the Form
 const handleInputChange = (field, value) => {
   if (field === "start_date" || field === "end_date") {
     setUpdatedEvent((prev) => ({
       ...prev,
       [field]: convertDatetimeLocalToISO(value),  
     }));
   } else {
     setUpdatedEvent((prev) => ({ ...prev, [field]: value }));
   }
 };
 useEffect(() => {
   if (isEditModalOpen && selectedEvent) {
     setUpdatedEvent(selectedEvent);
   }
 }, [isEditModalOpen, selectedEvent]);
//  const handleUpdateEvent = async (e) => {
//    e.preventDefault(); // Prevent default form submission
 
//    // Ensure proper formatting for start_date and end_date
//    const startDate = updatedEvent.start_date
//      ? convertDatetimeLocalToISO(updatedEvent.start_date)
//      : convertDatetimeLocalToISO(selectedEvent.start_date); // Fallback to existing value
 
//    const endDate = updatedEvent.end_date
//      ? convertDatetimeLocalToISO(updatedEvent.end_date)
//      : convertDatetimeLocalToISO(selectedEvent.end_date); // Fallback to existing value
 
//    // Create form data
//    const form = new FormData();
//    form.append("event_id", updatedEvent.id);
//    form.append("event_title", updatedEvent.event_title || selectedEvent.event_title);
//    form.append("start_date", startDate);
//    form.append("end_date", endDate);
//    form.append("location", updatedEvent.location || selectedEvent.location);
//    form.append("organizer_name", updatedEvent.organizer_name || selectedEvent.organizer_name);
//    form.append("contact_number", updatedEvent.contact_number || selectedEvent.contact_number);
//    form.append("description", updatedEvent.description || selectedEvent.description);
//    form.append("email_address", updatedEvent.email_address || selectedEvent.email_address);
//    form.append("country_code", updatedEvent.country_code || selectedEvent.country_code);
 
//    if (updatedEvent.file) {
//      form.append("file", updatedEvent.file);
//    }
 
//    try {
//      const res = await axios({
//        method: "POST",
//        url: `${logigloUrl}/updateEvent`,
//        data: form,
//        headers: {
//          "Content-Type": "multipart/form-data",
//          "Authorization": `Bearer ${JSON.parse(account).token}`,
//          "Accept": "multipart/form-data",
//        },
//      });
//      console.log("Event updated successfully:", res.data);
//      setUpdatedEvent({});
//      setIsEditModalOpen(false);
//    } catch (err) {
//      console.error("Error updating event:", err.response?.data || err.message);
//      alert("Failed to update event. Please check all fields and try again.");
//    }
//  };

const handleUpdateEvent = async (e) => {
  console.log("Data",updatedEvent)
  e.preventDefault(); // Prevent default form submission

  // Ensure proper formatting for start_date and end_date
  const startDate = updatedEvent.start_date
    ? convertDatetimeLocalToISO(updatedEvent.start_date)
    : convertDatetimeLocalToISO(selectedEvent.start_date); // Fallback to existing value

  const endDate = updatedEvent.end_date
    ? convertDatetimeLocalToISO(updatedEvent.end_date)
    : convertDatetimeLocalToISO(selectedEvent.end_date); // Fallback to existing value

  // Create form data
  const form = new FormData();
  form.append("event_id", updatedEvent.id);
  form.append("event_title", updatedEvent.event_title || selectedEvent.event_title);
  form.append("start_date", startDate);
  form.append("end_date", endDate);
  form.append("location", updatedEvent.location || selectedEvent.location);
  form.append("organizer_name", updatedEvent.organizer_name || selectedEvent.organizer_name);
  form.append("contact_number", updatedEvent.contact_number || selectedEvent.contact_number);
  form.append("description", updatedEvent.description || selectedEvent.description);
  form.append("email_address", updatedEvent.email_address || selectedEvent.email_address);
  form.append("country_code", updatedEvent.country_code || selectedEvent.country_code);

  if (updatedEvent.file) {
    form.append("file", updatedEvent.file);
  }

  try {
    const res = await axios({
      method: "POST",
      url: `${logigloUrl}/updateEvent`,
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${JSON.parse(account).token}`,
        "Accept": "multipart/form-data",
      },
    });

    console.log("Full response:", res); // Log the full response to understand the data

    // Check if the response status is 200 and if the update was successful
    if (res.status === 200) {
      console.log("Event updated successfully:", res.data);
      toast.info("Thank you for your interest!");

      setUpdatedEvent({}); // Reset updated event state
      setIsEditModalOpen(false); // Close the modal after success
    } else {
      console.error("Unexpected server response:", res.data);
      alert("Failed to update event. Please check all fields and try again.");
    }
  } catch (err) {
    // Handle any errors in the request
    console.error("Error updating event:", err.response?.data || err.message);
    alert("Failed to update event. Please check all fields and try again.");
  }
};



 
 
const convertISOToDatetimeLocal = (isoDate) => {
  const date = new Date(isoDate);
  return date.toISOString().slice(0, 16);  
};
const convertDatetimeLocalToISO = (datetimeLocal) => {
  const date = new Date(datetimeLocal);
  const timeZone = "Asia/Kolkata"; 
  const zonedDate = utcToZonedTime(date, timeZone);
  const formattedDateForDatabase = format(zonedDate, "yyyy-MM-dd HH:mm:ss", {
    timeZone,
  });
  return formattedDateForDatabase; 
};

 
  const renderTabContent = () => {
    switch (activeTab) {
      case "Posts":
        return userData?.posts && userData.posts.length > 0 ? (
          userData.posts.reverse().map((post) => (
            <div key={post.post_id} className="related-tab-item">
              <h5 dangerouslySetInnerHTML={createMarkup(post.title)}></h5>
              <p dangerouslySetInnerHTML={createMarkup(post.text)}></p>
              <p className="date">Posted on: {formatDate(post.created_at)}</p>
            </div>
          ))
        ) : (
          <p className="no-posts-message">No posts were found</p>
        );

      case "Comments":
        return userData?.comments && userData.comments.length > 0 ? (
          userData.comments.reverse().map((comment, index) => (
            <div key={index} className="related-tab-item">
              <h5>{comment.post_title}</h5>
              <p dangerouslySetInnerHTML={createMarkup(comment.text)}></p>
              <p className="date">Commented on: {formatDate(comment.created_at)}</p>
            </div>
          ))
        ) : (
          <p>No comments were found</p>
        );

      case "Replies":
        return userData?.replies && userData.replies.length > 0 ? (
          userData.replies.reverse().map((reply) => (
            <div key={reply.reply_id} className="related-tab-item">
              <h5>{reply.post_title}</h5>
              <p dangerouslySetInnerHTML={createMarkup(reply.text)}></p>
              <p className="date">Replied on: {formatDate(reply.created_at)}</p>
            </div>
          ))
        ) : (
          <p>No replies were found</p>
        );

      // case "Events":
      //   return events.length > 0 ? (
      //     events.map((event) => (
      //       <div key={event.id} className="related-tab-item">
      //         <h5>{event.event_title}</h5>
      //         <p>Event Organizer:{event.organizer_name}</p>
      //         {/* <p>{event.description}</p> */}
      //         <p dangerouslySetInnerHTML={createMarkup(event.description)}></p>
      //         <p  >Event Date: {formatDate(event.start_date)}</p>

      //       </div>
      //     ))
      //   ) : (
      //     <p>No events were found</p>
      //   );
      case "Events":
        return events.length > 0 ? (
          events.map((event) => (
            <div key={event.id} className="related-tab-item">
              <div className="event-options">
               

              {idToFetch === accountId && (
                 <RiMore2Fill
                    size={24}
                    onClick={() => toggleMenu(event.id)}
                    style={{ cursor: "pointer" }}
                 />
                )}
                {activeEvent === event.id && (
                  <div className="event-dropdown-menu">
                    <button onClick={() => handleEdit(event)}>Edit</button>
                    <button onClick={() => handleDeleteClick(event.id)}>Delete</button>
                  </div>
                )}
              </div>

              <h5>{event.event_title}</h5>
              <p>Event Organizer: {event.organizer_name}</p>
              <p dangerouslySetInnerHTML={createMarkup(event.description)}></p>
              <p>Event Date: {formatDate(event.start_date)}</p>
            </div>
          ))
        ) : (
          <p>No events were found</p>
        );


      default:
        return <p>Invalid Tab</p>;
    }
  };
//  const editor = useRef()
  //console.log("Content",editor?.current?.getContents())
  return (
    <div className="profile-container">
      {/* Top Header Section */}
      <div className="top-header">
        <div style={{ display: "flex", flexDirection: "column" }} className="top-header-left-con">
          <div className="breadcrumb">Home</div>
          <div className="user-display-name">{userData?.first_name}</div>
        </div>
        <div>
          {/* <Link to={`/userEvents/${idToFetch}`}>
            <button className="flag-button">Events</button>
          </Link> */}
          {idToFetch === accountId && (
            <Link to={`/forumDashboard/UserSettings`}>
              <button className="edit-profile-btn">Edit Profile</button>
            </Link>
          )}
        </div>
      </div>

      <div className="content-wrapper">
        <div className="sidebar">
          <div className="profile-info-img-con">
            <img
              src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg"
              alt="User"
              className="user-image-dashboard"
            />
            <h5 className="user-name">@{userData?.first_name}</h5>
          </div>
        </div>

        <div className="main-content">
          <div className="profile-stats">
            <div className="stat-item">
              <div className="profile-dashboard-stat-value">{userData?.number_of_posts}</div>
              <div className="stat-label">Posts</div>
            </div>
            <div className="stat-item">
              <div className="profile-dashboard-stat-value">{userData?.number_of_replies}</div>
              <div className="stat-label">Replies</div>
            </div>
            {/* <div className="stat-item">
              <div className="profile-dashboard-stat-value">{userData?.number_of_comments}</div>
              <div className="stat-label">Comments</div>
            </div> */}
            <div className="stat-item">
              <div className="profile-dashboard-stat-value">{events.length}</div>
              <div className="stat-label">Events</div>
            </div>
            {/* <div className="stat-item">
              <div className="profile-dashboard-stat-value">{userData?.helpful_replies}</div>
              <div className="stat-label">Helpful reply</div>
            </div> */}
          </div>

          <div className="tabs">
            {["Posts", "Replies", "Events",].map((tab) => (
              <div
                key={tab}
                className={`tab ${activeTab === tab ? "active" : ""}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </div>
            ))}
          </div>

          <div className="tab-content">{renderTabContent()}</div>
        </div>
        {isDeleteModalOpen && (
        <div className="Event-delete-modal">
          <div className="Event-delete-modal-content">
            <h4>Are you sure you want to delete this event?</h4>
            <p>This event will be permanently deleted.</p>
            <button  onClick={deleteEvent}>Proceed</button>
            <button  style={{backgroundColor:'skyblue'}} onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}

{isEditModalOpen && (
  <div className="edit-modal">
    <div className="edit-modal-content">
      <h4>Edit Event</h4>
      <form onSubmit={(e) => handleUpdateEvent(e)}>
        <label className="event-title-label">
          Event Title:
          <input
            className="event-title-input"
            type="text"
            value={updatedEvent.event_title || ""}
            onChange={(e) => handleInputChange("event_title", e.target.value)}
          />
        </label>
        <label className="organizer-name-label">
          Organizer Name:
          <input
            className="organizer-name-input"
            type="text"
            value={updatedEvent.organizer_name || ""}
            onChange={(e) => handleInputChange("organizer_name", e.target.value)}
          />
        </label>
        <label className="description-label">
          Description:
          <ReactQuill
         // ref={editor}
            className="description-reactquill"
            value={updatedEvent.description || " "}
            // onChange={(value) => setUpdatedEvent({...updatedEvent,description:value})
            // //   {
            // //    console.log("Vlll",value)
            // // handleInputChange("description", value);
            // //  // handleInputChange("description",value)
            // // }
            // }
            onChange={(content, delta, source, editor)=>{
                console.log("Quill",editor?.getHTML())
                handleInputChange("description", editor?.getHTML())
            }}
            theme="snow"
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                ["link", "image"],
                ["clean"],
              ],
            }}
            formats={[
              "header", "bold", "italic", "underline", "strike", "blockquote",
              "list", "bullet", "indent", "link", "image",
            ]}
            style={{ borderRadius: "5px", height: 'fit-content', width: '100%', marginBottom: '3px' }}
          />
        </label>
        <label className="country-code-label">
          Country Code:
          <input
            className="country-code-input"
            type="text"
            value={updatedEvent.country_code || ""}
            onChange={(e) => handleInputChange("country_code", e.target.value)}
          />
        </label>
        <label className="contact-number-label">
          Contact Number:
          <input
            className="contact-number-input"
            type="tel"
            value={updatedEvent.contact_number || ""}
            onChange={(e) => handleInputChange("contact_number", e.target.value)}
          />
        </label>
        <label className="email-address-label">
          Email Address:
          <input
            className="email-address-input"
            type="email"
            value={updatedEvent.email_address || ""}
            onChange={(e) => handleInputChange("email_address", e.target.value)}
          />
        </label>
        <label className="location-label">
          Location:
          <input
            className="location-input"
            type="text"
            value={updatedEvent.location || ""}
            onChange={(e) => handleInputChange("location", e.target.value)}
          />
        </label>
        <label className="start-date-label">
          Start Date:
          <input
            className="start-date-input"
            type="datetime-local"
            value={updatedEvent.start_date ? convertISOToDatetimeLocal(updatedEvent.start_date) : ""}
            onChange={(e) => handleInputChange("start_date", e.target.value)}
          />
        </label>
        <label className="end-date-label">
          End Date:
          <input
            className="end-date-input"
            type="datetime-local"
            value={updatedEvent.start_date ? convertISOToDatetimeLocal(updatedEvent.end_date) : ""}
            onChange={(e) => handleInputChange("end_date", e.target.value)}
          />
        </label>
        <label className="file-label">
          File:
          <input
            className="file-input"
            type="file"
            accept=".jpg,.jpeg,.png,.pdf" // Accept specific file types
            onChange={(e) => handleInputChange("file", e.target.files[0])}
          />
        </label>
        <div className="button-container">
          <button className="update-button" type="submit">Update Event</button>
          <button className="cancel-button" type="button" onClick={() => setIsEditModalOpen(false)}>Cancel</button>
        </div>
        <ToastContainer/>
      </form>
    </div>
  </div>
)}


      </div>


    </div>
  );
};

export default UserProfile;
