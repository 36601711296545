import React, { useEffect, useState, useRef } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import './index.css'

const Footer = () => {
    return(
        <div className="copy-rights-outer-con">
        <div className="copy-rights-inner-con">
          <p className="copy-rights-text">
            © 2024 Logiframe Solutions |
            <Link className="home-page-links" to="/terms">
              {" "}
              Terms{" "}
            </Link>{" "}
            | All Rights Reserved |
            <Link className="home-page-links" to="/ContactUs">
              {" "}
              Contact Us |
            </Link>

            <Link className="home-page-links" to="/privacy">
              {" "}
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    )
}


export default Footer