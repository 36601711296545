import React from 'react';
import './index.css';
import { TiGroup } from "react-icons/ti";
import { VscBriefcase } from 'react-icons/vsc';
import { RiArticleLine } from 'react-icons/ri';
import { MdEvent } from 'react-icons/md';
import { Helmet } from "react-helmet-async";
const Navbar = React.lazy(() => import("../../Navbar"));
const Footer = React.lazy(() => import("../../LandingPageComponents/footer"));

const BrowcherComponent = () => {
  const Cards = [
    {
      title: 'Careers',
      description: 'Discover exciting career opportunities with us. Shape your future with our vibrant and dynamic team.',
      icon: <VscBriefcase size="2em" />,
      pdfPath: '/PDFs/careerbrochure.pdf',
    },
    {
      title: 'Education',
      description: 'Dive into our educational offerings. Explore courses designed to inspire and empower learners.',
      icon: <TiGroup size="2em" />,
      pdfPath: '/PDFs/Educationbrochure.pdf',
    },
    {
      title: 'Events',
      description: 'Stay updated with the latest events. Connect, learn, and grow with our curated experiences.',
      icon: <MdEvent size="2em" />,
      pdfPath: '/PDFs/Eventsbrochure.pdf',
    },
    {
      title: 'Forum',
      description: 'Engage with thought-provoking articles and stories. Dive into our world of knowledge and creativity.',
      icon: <RiArticleLine size="2em" />,
      pdfPath: '/PDFs/forumbrochureformat.pdf',
    },
  ];

  const handleCardClick = (pdfPath) => {
    window.open(pdfPath, '_blank');
  };

  return (
    <>
    <Navbar/>

     <Helmet>
          <title>Logiglo Brochures</title>
          <link rel="canonical" href="https://logiglo.com/Brochures" />
          <meta property="og:url" content="https://logiglo.com/Brochures" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Logiglo Brochures" />
          <meta property="og:image" content="https://opengraph.b-cdn.net/production/images/f8ba140a-981c-4421-a09e-ebae95b0534f.png?token=FXByuwPXqDXbCAr2fdnElBC4FiDHbW6vOC8r-8b_Kg0&height=675&width=1200&expires=33259546757" />
          <meta 
          property="og:description" 
          content="Explore insightful articles, expert opinions, and the latest trends in global supply chain management and logistics on the Logiglo Blog. Stay informed and ahead in your career." 
        />
          </Helmet>
    <div className="brochure-container">
      <div className="brochure-header">
        <h3>"Building Connections Through Knowledge"</h3>
        <p>Access detailed brochures and download to understand what we bring to the table.</p>
      </div>
      <div className="brochure-grid">
        {Cards.map((card, index) => (
          <div
            key={index}
            className="brochure-card"
            onClick={() => handleCardClick(card.pdfPath)}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-icon">{card.icon}</div>
            <h2 className="card-title">{card.title}</h2>
            <p className="card-description">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default BrowcherComponent;
