import React, { useEffect, useState, useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function Chatbot() {
  return (
    <div>
      <TawkMessengerReact
        propertyId="677cceb5af5bfec1dbe7b4bf"
        widgetId="1igvoeua0"
      />
    </div>
  );
}

export default Chatbot;
