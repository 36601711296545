import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./EducationCourseDetailedPage.css";
import RequestButton from "./RequestButton";
import { logigloUrlBase } from "../../../Config";
import { useParams } from "react-router-dom";
import { logigloUrl } from "../../../Config";
import CourseCurriculumMainAccordion from "./CourseCurriculum";
import Advertisement from "../../../advertisement";
import { ToastContainer, toast } from "react-toastify";
import { Helmet} from 'react-helmet-async';
import "react-toastify/dist/ReactToastify.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
const Navbar = React.lazy(() => import("../../../Navbar"));

// const Footer = React.lazy(() => import("../../footer"));

const EducationCourseDetailedPage = () => {
  const { id } = useParams();
  const [cardInformation, setCardInformation] = useState("");
  useEffect(() => {
    const card = async () => {
      try {
        const data = await fetch(`${logigloUrl}/getCourseDetails/${id}`);
        const getdata = await data.json();
        const carddata = getdata.course;
        setCardInformation(carddata);
        console.log("carddata===>", carddata);
      } catch (err) {
        console.log("error", err);
      }
    };
    card();
  }, []);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const courseDescription = cardInformation?.course_description || "";

  const words = courseDescription.split(" ");
  const truncatedDescription =
    words.length > 10
      ? `${words.slice(0, 10).join(" ")}...`
      : courseDescription;

  const shareUrl = `${logigloUrlBase}/CourseDetailedInfo/${id}`;

  return (
    <>
      <Navbar />

      <Helmet>
        <meta
          name="title"
          property="og:title"
          content={cardInformation.course_title}
        />
        <meta
          name="description"
          property="og:description"
          content={cardInformation.course_description}
        />
        <meta
          name="image"
          property="og:image"
          content={cardInformation.course_thumbnail_url}
        />
        <meta
          property="og:url"
          content={`https://logiglo.com/CourseDetailedInfo/${cardInformation.id}`}
        />
        <meta property="og:type" content="article" />
        <title>{cardInformation.course_title}</title>
      </Helmet>

      <div className="education-full-page">
        <div className="edu-ads-contaner">
          <Advertisement module="Education" type="left" />
        </div>

        <div className="education-detail-page-container">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Link to="/CourseList">
                <button className="back-to-courseList-button">Back</button>
              </Link>
            </div>
            <div className="education-share-buttons">
              <FacebookShareButton
                url={shareUrl}
                quote={cardInformation.course_title}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={cardInformation.course_title}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                title={cardInformation.course_title}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <WhatsappShareButton
                url={shareUrl}
                title={cardInformation.course_title}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
          </div>

          <div className="education-detail-course-card">
            <div className="education-detail-course-image">
              <img
                src={cardInformation.course_thumbnail_url}
                alt={cardInformation.course_title}
                style={{ height: "300px", objectFit: "cover" }}
              />
            </div>
            <div className="education-detail-course-content">
              <h4>{cardInformation.course_title}</h4>
              <p
                dangerouslySetInnerHTML={createMarkup(truncatedDescription)}
              ></p>
              <p>
                <span className="education-detail-subHeading">
                  Institute Name:
                </span>{" "}
                {cardInformation.institute_name}
              </p>

              <p>
                <span className="education-detail-subHeading">Instructor:</span>{" "}
                {cardInformation.instructor_name}
              </p>

              <a
                className="education-detail-link"
                href={cardInformation.brochure_img_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Brochure
              </a>
              <div>
                <a
                  className="link"
                  href={cardInformation.youtube_short_video_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Video
                </a>
              </div>
            </div>
            {/* <div className="education-detail-course-image">
            <img src={cardInformation.course_thumbnail_url} alt={cardInformation.course_title} style={{ height: "300px", objectFit: "cover" }} />
         </div> */}
          </div>
          <div className="request-button-container">
            <RequestButton />
            <ToastContainer />
          </div>
          <div>
            <h4>About The Course</h4>

            <p
              dangerouslySetInnerHTML={createMarkup(
                cardInformation.course_description
              )}
            ></p>
          </div>
          <div>
            <CourseCurriculumMainAccordion item={cardInformation} />
          </div>
          <div className="request-button-container">
            <RequestButton />
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default EducationCourseDetailedPage;
