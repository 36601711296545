import React, { useState, useEffect, useMemo } from "react";
import LayoutWrapper from "../../components/utility/layoutWrapper";
import SearchFavoriteIcon from "../../assests/search-favorite-1";
import Papersheet from "../../components/utility/papersheet";
import { FullColumn } from "../../components/utility/rowColumn";
import IntlMessages from "../../components/utility/intlMessages";
import { Link, useLocation, Redirect, useHistory } from "react-router-dom";
import { utcToZonedTime } from 'date-fns-tz';
import { parseISO, format, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';
import axios from "axios";
import { logigloUrl } from '../Config/index'
import { useDispatch, useSelector } from "react-redux";
import "./index.css";



const Advertisement = ({ module, type, onEmpty, onLoaded, renderOnly = 1 }) => {
  const [adsData, setAdsData] = useState([]);
  // const [viewStartTime, setViewStartTime] = useState(null);
  const [viewStartTimes, setViewStartTimes] = useState({});
  const [currentAdId, setCurrentAdId] = useState(null);

  useEffect(() => {
   
    axios
      .get(`${logigloUrl}/advertisementData`)
      .then((response) => {
        setAdsData(response.data);

        const filteredAds = response.data.filter(
          (ad) => ad.module === module && ad.type === type && ad.render === 1  
        );

        if (filteredAds.length === 0) {
          onEmpty();
        } else {
          onLoaded();
        }
      })
      .catch((error) => {
        console.error("Error fetching ads data:", error);
         // Treat errors as empty ads
        //  onEmpty();
      });
  }, []);


   // log impressions when ads render
  //  useEffect(() => {
  //   adsData.forEach((ad) => {
  //     if (ad.type === type && ad.render === renderOnly && ad.module === module) {
  //       logAdvertisementAction(ad.id, "impression");
  //     }
  //   });
  // }, [adsData, type, renderOnly, module]);

 

  useEffect(() => {
    adsData.forEach((ad) => {
        if (ad.type === type && ad.render === renderOnly && ad.module === module) {
            logAdvertisementAction(ad.id, "impression");

            // Record the time when the ad is first viewed
            setViewStartTimes((prevTimes) => ({
                ...prevTimes,
                [ad.id]: prevTimes[ad.id] || Date.now(), // Set start time if not already set
            }));
        }
    });
}, [adsData, type, renderOnly, module]);






const logAdvertisementAction = async (id, action) => {
  let timeSpent = 0;

  if (action === "engagement" && viewStartTimes[id]) {
      let endTime = Date.now();
      timeSpent = endTime - viewStartTimes[id]; // Calculate time spent for this ad

      console.log(`Timespent for ad ${id}: ${timeSpent} ms`);
  }

  try {
      const body = { action };
      if (action === "engagement") body.timeSpent = timeSpent;

      const response = await fetch(`${logigloUrl}/advertisements/${id}/action`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
      });

      console.log(await response.text());

      // Reset the viewStartTime for this ad after logging engagement
      if (action === "engagement") {
          setViewStartTimes((prevTimes) => {
              const updatedTimes = { ...prevTimes };
              delete updatedTimes[id]; // Remove the logged ad's start time
              return updatedTimes;
          });
      }
  } catch (error) {
      console.error("Error logging advertisement action:", error);
  }
};







  return (
    <div className="advertisements-main-con">
      {adsData
        .filter(
          (item) =>
            item.type === type &&
            item.render === renderOnly &&
            item.module === module
        )
        .map((item) => {
          const isInternal = item.navigation_url === "/ContactUs"; // Check if it's an internal route
  
          return isInternal ? (
            <Link
              to={item.navigation_url}
              key={item.id}
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => logAdvertisementAction(item.id, "click")}
            >
              <div
                className="each-ad-container"
                style={{ margin: "2px", marginRight: "10px" }}
                onClick={() => logAdvertisementAction(item.id, "engagement")}
              >
                <img src={item.image_url} alt={item.title} className="ad-image-styles" />
                <h5 className="ad-title">{item.title}</h5>
                <p className="ad-description">{item.text}</p>
              </div>
            </Link>
          ) : (
            <a
              href={item.navigation_url}
              key={item.id}
              style={{ textDecoration: "none", color: "black" }}
              target="_blank" // Open in a new tab
              rel="noopener noreferrer" // Secure external links
              onClick={() => logAdvertisementAction(item.id, "click")}
              
            >
              <div
                className="each-ad-container"
                style={{ margin: "2px", marginRight: "10px" }}
                onClick={() => logAdvertisementAction(item.id, "engagement")}
              >
                <img src={item.image_url} alt={item.title} className="ad-image-styles" />
                <h5>{item.title}</h5>
                <p>{item.text}</p>
              </div>
            </a>
          );
        })}
    </div>
  );
};

export default Advertisement;
